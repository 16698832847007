/* eslint-disable react/no-danger */
import React, {useState} from 'react'

// parse out the extra text node and return if it exists
const Description = ({ data, className }) => {
  if (!data) return null
  return <div className={className} dangerouslySetInnerHTML={{ __html: data.childMarkdownRemark.html }} />
}

function Accordion({ data }) {
  const { accordionTitle, accordionContent } = data;
  const [open, setOpen] = useState(false);

  const toggleAccordion = () => {
    setOpen(!open);
  }

  return (
    <div className={`accordionContainer ${open ? 'open' : 'closed'}`}>
      <h2 className='accordionTitle' onClick={toggleAccordion}>{accordionTitle}</h2>
      <Description data={accordionContent} className={`accordionContent ${open ? 'open':''}`} />

    </div>
  )
}

export default Accordion

import React from 'react'

import getLandingPageModules from 'utils/getLandingPageModules'

// import { Column } from 'components/StyledComponents/Column'
// import { Row } from 'components/StyledComponents/Row'
import Container from 'components/common/Container'

function ThreeColumn({ data }) {
  // console.log('⭐⭐⭐ three column data: ', data)
  const { section1, section2, section3, cssClass } = data
  return (
    <Container constraints="centered" className={cssClass}>
      <div className="row row-threeColumn">
        <div className="firstColumn">{section1 && getLandingPageModules(section1)}</div>
        <div className="secondColumn">{section2 && getLandingPageModules(section2)}</div>
        <div className="thirdColumn">{section2 && getLandingPageModules(section3)}</div>
      </div>
    </Container>
  )
}

export default ThreeColumn

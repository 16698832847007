import React from 'react'
import FlipCard from '../FlipCard'

function ContentfulComponentFlipCard({ data }) {
  const { frontImage, frontTextField, rearImage, rearTextField, link } = data
  // console.log('🚀 ~ file: FlipCard.js:6 ~ ContentfulComponentFlipCard ~ data:', data)
  const { childMarkdownRemark: frontText } = frontTextField || {}
  const { childMarkdownRemark: rearText } = rearTextField || {}

  return (
    <FlipCard
      frontTextHTML={frontText.html}
      frontImage={frontImage?.gatsbyImageData}
      rearImage={rearImage?.gatsbyImageData}
      rearTextHTML={rearText?.html}
      link={link}
    />
  )
}

export default ContentfulComponentFlipCard

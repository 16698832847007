/* eslint-disable react/no-danger */
import React from 'react'
import { GatsbyImage as Img } from 'gatsby-plugin-image'

const CardContent = ({ data }) => {
  if (!data) return null
  return <div className="card-content" dangerouslySetInnerHTML={{ __html: data }} />
}

const CardLink = ({ link, children }) => {
  if (link) {
    return (
      <a href={link} className="content-wrapper" target="_blank" rel="noreferrer">
        {children}
      </a>
    )
  }
  return <div className="content-wrapper">{children}</div>
}

const Card = ({ frontTextHTML, frontImage, rearImage, rearTextHTML, link }) => (
  <div className="card">
    <CardLink link={link}>
      {frontImage && (
        <div className="card-front">
          <Img image={frontImage} alt={frontImage?.title || 'Untitled Image'} />
          <CardContent data={frontTextHTML} />
        </div>
      )}

      {rearImage && (
        <div className="card-back">
          <Img image={rearImage} alt={rearImage?.title || 'Untitled Image'} />
          <CardContent data={rearTextHTML} />
        </div>
      )}
    </CardLink>
  </div>
)

export default Card

import React from 'react'

import getLandingPageModules from 'utils/getLandingPageModules'
import Container from 'components/common/Container'

function TwoColumn({ data }) {
  const { section1, section2, cssClass, title } = data
  return (
    <Container constraints="centered" className={cssClass}>
      <div className="row row-twoColumn ">
        <div className="firstColumn">{section1 && getLandingPageModules(section1)}</div>
        <div className="secondColumn">{section2 && getLandingPageModules(section2)}</div>
      </div>
    </Container>
  )
}

export default TwoColumn

/* eslint-disable react/no-danger */
import React, { useEffect } from 'react'
import { scrollTo } from 'utils/scrollTo'

const TheParagraph = ({ data }) => {
  useEffect(() => {
    const textAnchorLink = document.querySelector('.anchor-link')
    if (textAnchorLink) {
      textAnchorLink.addEventListener('click', (e) => {
        e.preventDefault()
        scrollTo()
      })
    }
  }, [])

  if (!data) return null
  return <div className="text-section" dangerouslySetInnerHTML={{ __html: data.childMarkdownRemark.html }} />
}

function Paragraph({ data }) {
  const { paragraph } = data

  return <TheParagraph data={paragraph} />
}

export default Paragraph
